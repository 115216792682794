//@ts-check

import React from 'react';

//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

import { NavLink } from 'react-router-dom';
import { fixLink } from '../../../helpers/fixLink';
import { twJoin } from 'tailwind-merge';

/**
 * @type {React.FC<{children:import('react').ReactNode,className:React.HTMLAttributes["className"],href:string,isEditMode?:boolean,openInNewWindow?:boolean}>}
 *
 * @returns - a link without any classes by default that uses the react router as intended
 */
export const CustomLink = ({
  children,
  className,
  href,
  isEditMode = false,
  openInNewWindow = false,
}) => {
  if (isInternalURL(href) && !href.includes('#')) {
    return (
      <NavLink
        className={className}
        to={flattenToAppURL(href)}
        target={twJoin(openInNewWindow ? '_blank' : '_self')}
        rel="noreferrer"
        aria-disabled={isEditMode}
      >
        {/** @ts-ignore */}
        {children}
      </NavLink>
    );
  }
  return (
    <a
      target={twJoin(openInNewWindow ? '_blank' : '_self')}
      aria-disabled={isEditMode}
      className={className}
      href={fixLink(href)}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
