//@ts-check
import React, { useState, useEffect } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { CustomContainer } from '../general';
import { ImagePlaceholder } from '../../assets/icons/buttons/ImagePlaceholder';
import { CustomLink } from '../general/CustomLink/CustomLink';

/**
 *
 * @param {{title:string, img:string, link:string, verticalOffset:'center'|'top'|'bottom'|undefined}} param0
 * @returns
 */
const Item = ({ title, img, link, verticalOffset }) => {
  const [imageIsVisible, setImageIsVisible] = useState(true);

  useEffect(() => {
    setImageIsVisible(true);
  }, [img]);

  return (
    <div className="h-[300px] md:h-[450px] w-full relative">
      {imageIsVisible ? (
        <img
          className="w-full h-full object-cover relative -z-10"
          style={{
            objectPosition: `center ${
              verticalOffset ? verticalOffset : 'center'
            }`,
          }}
          src={img}
          alt={title}
          onError={() => setImageIsVisible(false)}
        />
      ) : (
        <div className="w-full h-full object-cover relative -z-10 flex justify-center items-center">
          <ImagePlaceholder
            className="group-hover:[&_path]:stroke-dsBlue-400 dark:[&_path]:stroke-[#c1c1c1]"
            size="35"
            color="#000000"
          />
        </div>
      )}
      {title && (
        <CustomContainer
          size="ensino-institucional"
          className="h-full flex col flex-col-reverse absolute top-0 -translate-x-1/2 left-1/2 "
          alignCenter={true}
        >
          <div className="w-full md:w-[394px] z-10 bg-white dark:bg-[#001C3A] gap-5 p-5 md:shadow mb-[28px]">
            <div className="text-dsGray-900 dark:text-[#F8FAFA] font-bold">
              <h4 className="break-words text-[16px]">{title}</h4>
            </div>
            <div className="w-full flex justify-end items-center">
              <CustomLink
                href={link}
                className="text-[15px] text-[#2376DB] dark:text-[#A9C7FF] dark:hover:text-[#ffbe00] hover:text-[#ffbe00] font-semibold flex justify-right items-center gap-2"
              >
                Saiba mais <AiOutlineArrowRight className="text-[18px]" />
              </CustomLink>
            </div>
          </div>
        </CustomContainer>
      )}
    </div>
  );
};

export default Item;
