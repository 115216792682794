import React from 'react';
import { CustomContainer } from '../general/CustomContainers/CustomContainer';
import { IconArrowRight } from '../../assets/icons/buttons/IconArrowRight';
import { twJoin } from 'tailwind-merge';
import { IconsName } from '../../assets/icons/buttons';
export const ContactView = (props) => {
  const { data, isEditMode } = props;

  if (
    (!data.title || !data.titleOption) &&
    (!data.contact || data.contact?.length === 0) &&
    (!data.links || data.links?.length === 0 || !data.urlOption)
  ) {
    return (
      <div className="p-4 bg-slate-200 border border-solid border-black flex flex-col gap-y-2">
        <p className="text-2xl">Componente "Contact"</p>
        <p>
          Insira a quantidade de botões de texto que desejar. Cada bloco de
          texto pode ou não vir acompanhado de um ícone no início.
        </p>
        <p>
          Você também tem a opção de inserir título e links para o componente.
        </p>
      </div>
    );
  }

  return (
    <CustomContainer
      size={data.containerOption}
      alignCenter
      className="flex flex-col gap-y-4 px-4 sm:px-0"
    >
      {data.titleOption && data.title && (
        <h2 className="title_large-web_bold mb-2 dark:text-[#faf8f8]">
          {data.title}
        </h2>
      )}
      {data.contact?.length > 0 && (
        <div className="flex flex-col gap-y-2">
          {data.contact.map((contact, index) => {
            let IconComponente;
            const regexExp = new RegExp('^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+');
            const isEmail = regexExp.test(contact.input);
            if (contact.icon) {
              IconComponente = IconsName[contact.icon];
            }
            return (
              <div key={index} className="flex flex-row gap-x-2 items-center">
                {contact.iconOption && contact.icon && (
                  <IconComponente
                    size="24"
                    changeColorOnHover
                    className="shrink-0"
                  />
                )}
                {isEmail ? (
                  <a
                    href={`mailto:${contact.input}`}
                    className={twJoin(
                      'body_large-web_semibold text-[#005DB7] dark:text-[#A9C7FF] hover:text-[#FCBC00] dark:hover:text-[#FCBC00] ',
                      isEditMode && 'pointer-events-none',
                    )}
                  >
                    {contact.input}
                  </a>
                ) : (
                  <p className="dark:text-[#F8FAFA] body_large-web_regular">
                    {contact.input}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      )}
      {data.urlOption && data.links?.length > 0 && (
        <div className="flex flex-row gap-4 flex-wrap pl-0 ">
          {data.links.map((link, index) => {
            return (
              <a
                className={twJoin(
                  'flex flex-row gap-x-1 items-center group',
                  isEditMode && 'pointer-events-none',
                )}
                key={index}
                target={link.newTab && '_blank'}
                href={link.url}
              >
                <p className="label_large-web_semibold text-[#005DB7] group-hover:text-[#FCBC00] group-hover:dark:text-[#FCBC00] dark:text-[#A9C7FF] transition duration-300">
                  {link.title}
                </p>
                <IconArrowRight
                  className={twJoin(
                    '[&_*]:transition [&_*]:duration-300 shrink-0',
                    link.newTab && '-rotate-30',
                  )}
                  size="16"
                  color="#005DB7"
                  changeColorOnHover
                />
              </a>
            );
          })}
        </div>
      )}
    </CustomContainer>
  );
};
