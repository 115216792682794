//@ts-check
import React from 'react';
import { CustomLink } from '../../../../addons/utfpr_components/src/components/general/CustomLink/CustomLink';

/** @type {React.FC<{title:string,description:string,to:string,img:string}>} */
export const LoginCard = (props) => {
  return (
    <CustomLink
      href={props.to}
      className="flex group flex-col md:grid grid-rows-2 grid-cols-1 hover:shadow-xl shadow-md hover:shadow-slate-900/80 dark:hover:shadow-slate-400/40  bg-[#F8FAFA] dark:bg-[#001B3D] rounded-[3rem] overflow-hidden transition duration-300"
    >
      <img
        src={props.img}
        loading="lazy"
        className="object-cover h-full w-full"
      ></img>
      <div className="p-8 flex flex-col gap-8 justify-between">
        <section className="flex flex-col gap-8 group-hover:[&_*]:text-dsGray-800 [&_*]:text-dsGray-900 group-hover:dark:[&_*]:text-dsGray-100 dark:[&_*]:text-dsGray-50 ">
          <h4 className="dark:text-dsGray-50 font-bold">{props.title}</h4>
          <p className="dark:text-dsGray-50">{props.description}</p>
        </section>
        <button className="transition-all duration-300 rounded-full p-4 text-2xl dark:border-primary-300 border-primary-500 group-hover:border-dsYellow-500 dark:group-hover:border-dsYellow-500 dark:text-primary-300 text-primary-500 group-hover:text-dsYellow-500 dark:group-hover:text-dsYellow-500 border-2 font-bold">
          Acessar
        </button>
      </div>
    </CustomLink>
  );
};
