const numbersSchema = (props)=>(
    {
        title: "Número",
        addMessage: "Adicionar",
        fieldsets: [
            {
                id: "default",
                title: "Default",
                fields: ["number", "title", "desc"]
            }
        ],
        properties: {
            number: {
                title: "Número",
                widget: "number",

            },
            title: {
                title: "Título",
                widget: "text"
            },
            desc: {
                title: "Descrição",
                widget: "text"
            }
        },
        required: ["number", "title", "desc"]
    }
)
    

export const schemaUtfprNumbers = ({data, intl})=>{
    return{
        title: "UTFPR Numbers",
        fieldsets: [
            {
                id: "default",
                title: "Default",
                fields: ["numbers"]
            }
        ],
        properties: {
            numbers:{
                title: "Adicionar números",
                widget: "object_list",
                schema: numbersSchema
            }
        },
        required: []
    }
}