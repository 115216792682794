import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { useSelector, shallowEqual } from 'react-redux';

const Anontools = ({ user }) => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const content = useSelector((state) => state.content.data, shallowEqual);

  const { settings } = config;
  // bg-white rounded-md px-3 py-2 font-dsButton text-dsBlue-600
  return (
    !token && (
      <div className="flex gap-2">
        <div className="bg-white rounded-md px-3 py-2 font-dsButton text-dsBlue-600">
          {user === 'estudante' ? (
            <a href="https://sistemas2.utfpr.edu.br/login">
              <FormattedMessage id="Log in" defaultMessage="Log in" />
            </a>
          ) : (
            <Link
              aria-label="login"
              to={`${
                content?.['@id'] ? `${flattenToAppURL(content['@id'])}` : ''
              }/login-menu`}
            >
              <FormattedMessage id="Log in" defaultMessage="Log in" />
            </Link>
          )}
        </div>
        {settings.showSelfRegistration && (
          <div className="bg-white rounded-md px-3 py-2 font-dsButton text-dsBlue-600">
            <Link aria-label="register" to="/register">
              <FormattedMessage id="Register" defaultMessage="Register" />
            </Link>
          </div>
        )}
      </div>
    )
  );
};

export default Anontools;

Anontools.propTypes = {
  token: PropTypes.string,
  content: PropTypes.shape({
    '@id': PropTypes.string,
  }),
};

Anontools.defaultProps = {
  token: null,
  content: {
    '@id': null,
  },
};
