export const imagesSchema = (props) => ({
  title: "Imagem customizável",
  addMessage: "Adicionar",
  fieldsets: [
    {
      id: "default",
      title: "Default",
      fields: [
        "src",
        "resize",
        "justifySelf",
        "alignSelf"
      ]
    }
  ],

  properties: {
    src: {
      title: "Adicione um arquivo de imagem",
      widget: "object_browser",
      mode: "image"
    },
    resize: {
      title: "Escolha como a imagem será preenchida dentro do container",
      widget: "select",
      choices: [
        ['fill', 'fill'],
        ['contain', 'contain'],
        ['cover', 'cover'],
        ['none', 'none']
      ],
    },
    justifySelf: {
      title: "Escolha como a imagem será posicionada horizontalmente",
      widget: "select",
      choices: [
        ['start', 'start'],
        ['end', 'end'],
        ['center', 'center'],
        ['stretch', 'stretch']
      ]
    },
    alignSelf: {
      title: "Escolha como a imagem será posicionada verticalmente",
      widget: "select",
      choices: [
        ['start', 'start'],
        ['end', 'end'],
        ['center', 'center'],
        ['stretch', 'stretch']
      ]
    }
  },
  required: ['src', 'resize', 'justifySelf', 'alignSelf'],
});

export const geralSchema = (props) => ({
title: 'Imagens',
fieldsets: [
  {
    id: 'default',
    title: 'Default',
    fields: [
      'images',
      'layout',
      'container'
    ],
  },
],
properties: {
  images: {
    title: 'Adicionar imagens',
    widget: 'object_list',
    schema: imagesSchema,
  },
  layout: {
    title: 'Escolha o layout',
    widget: 'select',
    choices: [
      ['2 imagens', '2 imagens'],
      ['3 imagens v1', '3 imagens v1'],
      ['3 imagens v2', '3 imagens v2'],
      ['4 imagens', '4 imagens'],
      ['Grid', 'Grid'],
    ]
  },
  container: {
    title: 'Escolha o container das imagens',
    widget: 'select',
    choices: [
      ['Full', 'Tela inteira'],
      ['Medio', 'Médio'],
      ['Pequeno', 'Pequeno']
    ]
  }
},
required: ['images', 'layout', 'container'],
});

export const gridSchema = () => ({
title: 'Imagens',
fieldsets: [
  {
    id: 'default',
    title: 'Default',
    fields: [
      'images',
      'layout',
      'cols',
      'container'
    ],
  },
],
properties: {
  images: {
    title: 'Adicionar imagem',
    widget: 'object_list',
    schema: imagesSchema,
  },
  layout: {
    title: 'Escolha o layout',
    widget: 'select',
    choices: [
      ['2 imagens', '2 imagens'],
      ['3 imagens', '3 imagens'],
      ['Grid', 'Grid'],
    ],
  },
  cols: {
    title: 'Escolha o número de colunas no grid',
    widget: 'select',
    choices: [
      ['1', '1 coluna'],
      ['2', '2 colunas'],
    ],
  },
  container: {
    title: 'Escolha o container das imagens',
    widget: 'select',
    choices: [
      ['Full', 'Tela inteira'],
      ['Medio', 'Médio'],
      ['Pequeno', 'Pequeno']
    ]
  }
},
required: ['images', 'layout', 'cols', 'container'],
});