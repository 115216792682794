//@ts-check
import React from 'react';
import { IconMail } from '../../assets/icons/buttons/IconMail';
import { CustomContainer } from '../general/CustomContainers/CustomContainer';

const Text = ({ text, align, className }) => {
  const alignStyle = {
    left: 'text-left',
    right: 'text-right',
    center: 'text-center',
    full: 'text-justify',
  };

  return (
    <p
      className={`font-dsBody break-words text-base ${className} ${alignStyle[align]} dark:text-[#F8FAFA]`}
    >
      {text}
    </p>
  );
};

const TextComponentView = (props) => {
  const { data } = props;

  const emailMatch = data && data.text ? data.text.match(/\S+@\S+\.\S+/) : null;
  const isOnlyEmail = emailMatch && emailMatch[0] === data.text;
  return (
    <CustomContainer
      size={translateSize(data.container)}
      alignCenter={true}
      className="group"
    >
      <div className="break-words">
        {isOnlyEmail ? (
          <div className="flex items-center ">
            <IconMail
              changeColorOnHover={true}
              size={'16'}
              className="mr-2 cursor-pointer leading-[150%] group-hover:[&_path]:stroke-[#FCBC00]"
              color="#005DB7"
            />
            <a href={`mailto:${data.text}`}>
              <Text
                className="text-[#005DB7] leading-[150%] tracking-[0.5px] text-lg font-semibold group-hover:text-[#FCBC00]"
                text={data.text ?? ''}
                align={data.align ?? 'full'}
              />
            </a>
          </div>
        ) : (
          <Text
            text={data.text ?? 'Insira um texto'}
            align={data.align ?? 'full'}
            className=""
          />
        )}
      </div>
    </CustomContainer>
  );
};

/**
 *
 *
 * @returns {(import('../general/CustomContainers/types').Sizes)}
 * @param {'0'|'1'|'2'|'3'|undefined} size
 */
function translateSize(size) {
  switch (size) {
    case '0':
      return 'full-width';
    case '1':
      return 'ensino-institucional';
    case '2':
      return 'pesquisa-extensao';
    case '3':
      return 'pesquisa-institucional';
  }
  return 'ensino-institucional';
}

export default TextComponentView;
