//@ts-check
import React from 'react';
import { CustomContainer } from '../general/CustomContainers/CustomContainer';

const LoginComponentView = (props) => {
  const { data } = props;

  return (
    <CustomContainer
      size="ensino-institucional"
      alignCenter={true}
      className="flex justify-center"
    >
      <div className=" bg-white dark:bg-[#001B3D] shadow rounded-[30px] overflow-hidden sm:w-[350px] sm:h-[490px] md:w-[400px] md:h-[570px] lg:w-[410px] lg:h-[600px] xl:w-[495px] xl:h-[700px]">
        <img
          src={`data:image/jpg;base64, ${data?.src?.data || ''}`}
          className={`w-full h-[371px] object-cover sm:h-[200px] md:h-[270px] lg:h-[300px] xl:h-[371px]`}
        />
        <div className="flex flex-col px-[45px] py-[29px]">
          <h6 className={`text-[32px] font-semibold line-clamp-2 leading-10 text-[#000] dark:text-white`}>
            {data.title}
          </h6>
          <p className="text-[16px] leading-6 pt-[29px] line-clamp-2 text-[#000] dark:text-white">
            {data.description}
          </p>
          <div className="flex justify-center sm:mt-[45px] md:mt-[55px] lg:mt-[55px] 2xl:mt-[70px]">
            <a
              href={data.href}
              className="group w-[187px] h-[58px] flex items-center justify-center rounded-full border-2 border-[#005DB7] dark:border-white dark:hover:border-[#FCBC00] hover:border-[#FCBC00] dark:focus:border-[#00468C] focus:border-[#00468C]">
              <p className="text-[24px] font-bold text-[#005DB7] dark:text-white group-hover:text-[#FCBC00] group-focus:text-[#00468C]">
                {data.button}
              </p>
            </a>
          </div>
        </div>
      </div>
    </CustomContainer>
  );
};

export default LoginComponentView;
