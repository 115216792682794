/**
 * Routes.
 * @module routes
 */

import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';
import LoginMenu from './customizations/components/theme/LoginMenu/LoginMenu';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: config.getComponent('App').component, // Change this if you want a different component
    routes: [
      // Add your routes here
      { path: ['/login-menu', '/**/login-menu'], component: LoginMenu },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
