/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import { Logo, SearchWidget } from '@plone/volto/components';
import './styles.css';
import React from 'react';

import { MobileFooterNav } from './MobileFooterNav';
import { FooterSocials } from './FooterSocials';
import { DesktopFooterNav } from './DesktopFooterNav';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

const Footer = () => {
  return (
    <div className="bg-[#F6F6F6] dark:bg-[#001C3A] w-full" id="main-footer">
      <div className="container mx-auto w-full pb-32 sm:py-12 sm:flex sm:flex-row-reverse sm:justify-between sm:gap-x-10 lg:gap-x-24">
        <DesktopFooterNav />
        <MobileFooterNav />
        <div className="px-4 flex flex-col justify-between">
          <div className="my-10 sm:hidden">
            <SearchWidget className="border-solid border-[#FFBE00] border-2 dark:bg-[#001C3A]" />
          </div>
          <div>
            <a title="Voltar ao início" href="/" className="w-fit inline-block">
              <Logo withoutSubtitle={true} />
            </a>
            <p
              className={
                'text-left font-dsSubtitle font-bold uppercase mt-1 text-[#0D1117] dark:text-[#F8FAFA]'
              }
            >
              Universidade Tecnológica Federal do Paraná
            </p>
            <p className="text-left font-dsBody mt-5 text-[#0D1117] dark:text-[#F8FAFA]">
              Av. Sete de Setembro, 3165 Rebouças
            </p>
            <p className="text-left font-dsBody text-[#0D1117] dark:text-[#F8FAFA]">
              80230-901 Curitiba PR Brasil 🇧🇷
            </p>
            <p className="text-left font-dsBody text-[#0D1117] dark:text-[#F8FAFA]">
              +55 (41) 3310-4545
            </p>
          </div>
          <FooterSocials />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Footer);
