import { IconChevronDown } from '../../../../addons/utfpr_components/src/assets/icons/buttons/IconChevronDown'

export const MobileFooterNav = () => {

  const ToggleActive = (e) => {
    if (e.currentTarget.classList.contains('ativo')) {
      e.currentTarget.classList.remove('ativo');
    } else {
      e.currentTarget.classList.add('ativo');
    }
  };

  return (
    <div className="flex flex-col sm:hidden">
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/envolva peer/envolva p-4 border-b-[0.5px] border-[#C1C1C1] dark:border-[#8D9199] border-solid flex justify-between items-center"
      >
        <p className="text-xl md:text-2xl font-bold dark:text-[#F8FAFA]">Envolva-se</p>
        <IconChevronDown
          size={24}
          className={'group-[.ativo]/envolva:rotate-180 transition-all dark:[&_path]:stroke-[#F8FAFA] [&_path]:stroke-[#0D1117]'}
        />
      </button>
      <ul className="dark:[&_*]:text-[#F8FAFA] peer-[.ativo]/envolva:flex hidden list-none flex-col">
        <li className="font-dsBody flex">
          <a
            href="/comunidade"
            className="order-l-4 border-transparent focus:border-l-4  w-full pl-14 py-5  font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Comunidade
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/contato"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5  font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Contato
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/cursos/estudenautfpr"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Ingresso
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/institucional/conheca-os-campi"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            13 Campi
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/transparencia/transparencia-e-prestacao-de-contas"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Transparência e prestação de contas
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/contato/ouvidoria/ouvidoria"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Ouvidoria
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/contato/ouvidoria/perguntas-frequentes"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            FAQ
          </a>
        </li>
      </ul>
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/encontre peer/encontre p-4 border-b-[0.5px] border-[#C1C1C1] dark:border-[#8D9199] border-solid flex justify-between items-center"
      >
        <p className="text-xl md:text-2xl font-bold dark:text-[#F8FAFA]">Encontre aqui</p>
        <IconChevronDown
          size={24}
          className={'group-[.ativo]/encontre:rotate-180 transition-all dark:[&_path]:stroke-[#F8FAFA] [&_path]:stroke-[#0D1117]'}
        />
      </button>
      <ul className="dark:[&_*]:text-[#F8FAFA] font-semibold text-[#18181A] dark:hover:text-[#005DB7] peer-[.ativo]/encontre:flex list-none hidden flex-col">
        <li className="font-dsBody flex">
          <a
            href="/comunicacao/contato"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Área de imprensa
          </a>
        </li>
        {/* <li className="font-dsBody flex">
          <a
            href="#"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Apoio estudantil
          </a>
        </li> */}
        <li className="font-dsBody flex">
          <a
            href="/alunos/bolsas/auxilio-estudantil"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Bolsas/auxílios
          </a>
        </li>
        {/* <li className="font-dsBody flex">
          <a
            href="#"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Oportunidades
          </a>
        </li> */}
        <li className="font-dsBody flex">
          <a
            href="/editais/concursos"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Concursos
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/diplomas"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Diplomas 
          </a>
        </li>
      </ul>
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/gente  peer/gente p-4 border-b-[0.5px] border-[#C1C1C1] dark:border-[#8D9199] border-solid flex justify-between items-center"
      >
        <p className="text-xl md:text-2xl font-bold dark:text-[#F8FAFA]">Gente nossa</p>
        <IconChevronDown
          size={24}
          className={'group-[.ativo]/gente:rotate-180 transition-all dark:[&_path]:stroke-[#F8FAFA] [&_path]:stroke-[#0D1117]'}
        />
      </button>
      <ul className="dark:[&_*]:text-[#F8FAFA]  font-semibold text-[#18181A] dark:hover:text-[#005DB7] peer-[.ativo]/gente:flex list-none hidden flex-col">
        <li className="font-dsBody flex">
          <a
            href="#"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Portais UTFPR
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/servidores/servicos/ti/nuvem"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Nuvem
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/servidores/servicos/ti/e-mail"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Webmail
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/alunos/portal-do-aluno"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Portal do aluno
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/alunos/moodle"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Moodle
          </a>
        </li>
        <li className="font-dsBody flex">
          <a
            href="/login"
            className="border-l-4 border-transparent focus:border-l-4 focus:border-solid w-full pl-14 py-5 font-semibold text-[#18181A] dark:hover:text-[#005DB7] hover:border-l-[4px] hover:border-[#FCBC00] hover:bg-[#FFF8DF] dark:hover:border-[#005DB7] dark:hover:bg-[#A9C7FF]"
          >
            Login
          </a>
        </li>
      </ul>
    </div>
  )
}