import { IconArrowRight } from '../../assets/icons/buttons/IconArrowRight';
import apucarana from './Images/apucarana.jpg';
import campo_mourao from './Images/campo_mourao.jpg';
import cornelio_procopio from './Images/cornelio_procopio.jpg';
import curitiba from './Images/curitiba.jpg';
import dois_vizinhos from './Images/dois_vizinhos.jpg';
import francisco_beltrao from './Images/francisco_beltrao.jpg';
import guarapuava from './Images/guarapuava.jpg';
import londrina from './Images/londrina.jpg';
import medianeira from './Images/medianeira.jpg';
import pato_branco from './Images/pato_branco.jpg';
import ponta_grossa from './Images/ponta_grossa.jpg';
import santa_helena from './Images/santa_helena.jpg';
import toledo from './Images/toledo.jpg';

const CampusLayout = (props) => {
  const Campus = [
    { name: 'Apucarana', image: apucarana },
    { name: 'Campo Mourão', image: campo_mourao },
    { name: 'Cornélio Procópio', image: cornelio_procopio },
    { name: 'Curitiba', image: curitiba },
    { name: 'Dois Vizinhos', image: dois_vizinhos },
    { name: 'Francisco Beltrão', image: francisco_beltrao },
    { name: 'Guarapuava', image: guarapuava },
    { name: 'Londrina', image: londrina },
    { name: 'Medianeira', image: medianeira },
    { name: 'Pato Branco', image: pato_branco },
    { name: 'Ponta Grossa', image: ponta_grossa },
    { name: 'Santa Helena', image: santa_helena },
    { name: 'Toledo', image: toledo },
  ];

  return (
    <div className="mx-auto w-full gap-6 px-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
      {Campus.map((camp, index) => (
        <CampusItem
          campus={camp.name}
          image={camp.image}
          index={index * 2 + 1}
          data={props}
          editable={props.editable}
          onChangeBlock={props.onChangeBlock}
          block={props.block}
        />
      ))}
    </div>
  );
};

export default CampusLayout;

const CampusItem = ({ data, index, campus, image }) => {
  return (
    <div className="w-full max-w-[432px] h-[194px] flex bg-white dark:bg-[#001B3D] shadow-md rounded overflow-hidden">
      <div className="w-2/3 p-6 pr-9 flex flex-col justify-between xs:pr-12">
        <h4 className="font-bold text-[19px] leading-7 text-[#0D1117] dark:text-white xs:text-[22px] sm:h-[45px] xl:h-[28px]">{campus}</h4>
        <p className="line-clamp-2 text-base text-[#0D1117] dark:text-white">
          {data[`title${index}`]}
        </p>
        <a
          href={data[`href${index + 1}`] || ''}
          onClick={(ev) => {
            if (!data[`href${index + 1}`]) ev.preventDefault();
          }}
          className="group w-fit text-sm font-semibold text-[#005DB7] hover:text-[#ffbe00] flex items-center"
        >
          {data[`title${index + 1}`]} &nbsp;
          <IconArrowRight size="16" color="#005DB7" changeColorOnHover="true" className="dark:[&_path]:stroke-[#005DB7]" />
        </a>
      </div>
      <div className="w-[162px] h-[194px] rounded-t-[6px]">
        <img
          src={image} 
          alt={campus}
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};
