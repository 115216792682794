import { IconChevronDown } from '../../../../../../src/addons/utfpr_components/src/assets/icons/buttons/IconChevronDown';
import { NavLink } from 'react-router-dom';

/**
 *
 * @param {object} param0
 * @param {()=>any} param0.onClickLink
 * @returns
 */
export const NavMobile = ({ onClickLink }) => {
  const ToggleActive = (e) => {
    if (e.currentTarget.classList.contains('ativo')) {
      e.currentTarget.classList.remove('ativo');
    } else {
      e.currentTarget.classList.add('ativo');
    }
  };

  return (
    <div
      className="flex flex-col"
      onClick={(e) => {
        if (e.target instanceof HTMLAnchorElement) {
          onClickLink();
        }
      }}
    >
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/ensino peer/ensino px-12 py-4 flex justify-between"
      >
        <h1 className="border-transparent pb-[6px] group-[.ativo]/ensino:border-dsYellow-400 group-[.ativo]/ensino:border-b-4 group-[.ativo]/ensino:border-solid title_large-web_bold text-left text-[rgb(70,70,70)] dark:text-[#F8FAFA]">
          Ensino
        </h1>
        <IconChevronDown
          color="#464646"
          className="group-[.ativo]/ensino:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition"
        />
      </button>
      <ul className="peer-[.ativo]/ensino:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Estude na UTFPR"
            to="/cursos/estudenautfpr"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Estude na UTFPR
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Graduação"
            to="/cursos/graduacao"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Graduação
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Pós-graduação"
            to="/pesquisa-e-pos-graduacao/cursos"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Pós-graduação
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Educação a distância"
            to="/cursos/educacao-a-distancia"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Educação a distância
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Outros cursos"
            to="/cursos/outros"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Outros cursos
          </NavLink>
        </li>
      </ul>
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/pesquisa peer/pesquisa px-12 py-4 flex justify-between"
      >
        <h1 className="border-transparent pb-[6px] group-[.ativo]/pesquisa:border-dsYellow-400 group-[.ativo]/pesquisa:border-b-4 group-[.ativo]/pesquisa:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">
          Pesquisa
        </h1>
        <IconChevronDown
          color="#464646"
          className="group-[.ativo]/pesquisa:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition"
        />
      </button>
      <ul className="peer-[.ativo]/pesquisa:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Na graduação"
            to="/pesquisa-e-pos-graduacao/bolsas/pic"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Na graduação
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Mestrado e Doutorado"
            to="/pesquisa-e-pos-graduacao"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Mestrado e Doutorado
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Grupos de Pesquisa"
            to="/pesquisa-e-pos-graduacao/grupos-de-pesquisa"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Grupos de Pesquisa
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Laboratórios"
            to="/pesquisa-e-pos-graduacao/laboratorios-multiusuarios"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Laboratórios
          </NavLink>
        </li>
      </ul>
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/inovacao peer/inovacao px-12 py-4 flex justify-between"
      >
        <h1 className="border-transparent pb-[6px] group-[.ativo]/inovacao:border-dsYellow-400 group-[.ativo]/inovacao:border-b-4 group-[.ativo]/inovacao:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">
          Inovação
        </h1>
        <IconChevronDown
          color="#464646"
          className="group-[.ativo]/inovacao:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition"
        />
      </button>
      <ul className="peer-[.ativo]/inovacao:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Agência de Inovação"
            to="/inovacao/agencia"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Agência de Inovação
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Política de Inovação"
            to="/inovacao/politica-de-inovacao"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Política de Inovação
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Empreendedorismo"
            to="/inovacao/empreendedorismo"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Empreendedorismo
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Incubadora"
            to="/inovacao/empreendedorismo/incubadora-de-inovacoes-da-utfpr"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Incubadora
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Parque tecnológico"
            to="/inovacao/parque-tecnologico"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Parque Tecnológico
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Empresa Júnior"
            to="/inovacao/empreendedorismo/empresa-junior"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Empresa Júnior
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Vitrine Tecnológica"
            to="/inovacao/vitrine-tecnologica"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Vitrine Tecnológica
          </NavLink>
        </li>
      </ul>
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/extensao peer/extensao px-12 py-4 flex justify-between"
      >
        <h1 className="border-transparent pb-[6px] group-[.ativo]/extensao:border-dsYellow-400 group-[.ativo]/extensao:border-b-4 group-[.ativo]/extensao:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">
          Extensão
        </h1>
        <IconChevronDown
          color="#464646"
          className="group-[.ativo]/extensao:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition"
        />
      </button>
      <ul className="peer-[.ativo]/extensao:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Cursos Livres"
            to="/cursos/outros/extensao"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Cursos Livres
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Cultura"
            to="/cultura"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Cultura
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Ações Sociais"
            to="/"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Ações Sociais
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Extensão"
            to="/extensao"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Extensão
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Voluntariado"
            to="/"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Voluntariado
          </NavLink>
        </li>
      </ul>
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/inst peer/inst px-12 py-4 flex justify-between"
      >
        <h1 className="border-transparent pb-[6px] group-[.ativo]/inst:border-dsYellow-400 group-[.ativo]/inst:border-b-4 group-[.ativo]/inst:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]">
          Institucional
        </h1>
        <IconChevronDown
          color="#464646"
          className="group-[.ativo]/inst:rotate-180 dark:[&_path]:stroke-[#F8FAFA] transition"
        />
      </button>
      <ul className="peer-[.ativo]/inst:flex hidden list-none flex-col">
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Sobre a UTFPR"
            to="/institucional"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Sobre a UTFPR
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Quem é quem"
            to="/institucional/quem-e-quem"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 dark:hover:bg-[#00468C] pl-14 py-7 hover:bg-dsYellow-50 focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Quem é quem
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Estrutura"
            to="/estrutura"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Estrutura
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Documentos"
            to="/documentos"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Documentos
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Editais"
            to="/editais"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Editais
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Comunicação"
            to="/comunicacao"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Comunicação
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Comissões"
            to="/comissoes"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 pl-14 py-7 hover:bg-dsYellow-50 dark:hover:bg-[#00468C] focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Comissões
          </NavLink>
        </li>
        <li className="flex title_medium-tablet_semibold">
          <NavLink
            title="Avaliação Institucional"
            to="/servicos-a-comunidade/avaliacao"
            className="w-full border-l-4 border-transparent focus:border-solid focus:border-dsYellow-400 hover:border-solid hover:border-dsYellow-400 dark:hover:bg-[#00468C] pl-14 py-7 hover:bg-dsYellow-50 focus:bg-dsYellow-50 dark:focus:bg-[#00468C] text-[#464646] hover:text-[#464646]  dark:text-[#F8FAFA]"
          >
            Avaliação Institucional
          </NavLink>
        </li>
      </ul>
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/inst peer/inst px-12 py-4 flex justify-between"
      >
        <NavLink
          to="/acesso-a-informacao"
          className="hover:text-[#464646] border-transparent pb-[6px] group-[.ativo]/inst:border-dsYellow-400 group-[.ativo]/inst:border-b-4 group-[.ativo]/inst:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]"
        >
          Acesso à informação
        </NavLink>
      </button>
      <button
        onClick={(e) => ToggleActive(e)}
        className="group/inst peer/inst px-12 py-4 flex justify-between"
      >
        <NavLink
          to="/transparencia/transparencia-e-prestacao-de-contas"
          className="hover:text-[#464646] border-transparent pb-[6px] group-[.ativo]/inst:border-dsYellow-400 group-[.ativo]/inst:border-b-4 group-[.ativo]/inst:border-solid title_large-web_bold text-left text-[#464646] dark:text-[#F8FAFA]"
        >
          Prestação de contas
        </NavLink>
      </button>
    </div>
  );
};
