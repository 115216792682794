import View from './View';
import Edit from './Edit';
import buttonsPanelIcon from '../../assets/icons/buttonsPanel.svg';

export default {
  id: 'buttonsPanel',
  title: 'Buttons Panel',
  group: 'common',
  view: View,
  edit: Edit,
  icon: buttonsPanelIcon,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
};
