import LoginView from './LoginView.jsx';
import LoginEdit from './LoginEdit.jsx';
import icon from '../../assets/icons/loginCard.svg';

export default {
  id: 'login',
  title: 'Login Card',
  group: 'common',
  view: LoginView,
  edit: LoginEdit,
  icon: icon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};
