import {SidebarPortal} from "@plone/volto/components"
import ViewUtfprNumbers from "./View.jsx"
import DataUtfprNumbers from "./Data.jsx";

const EditUtfprNumbers = (props)=>{

    return(
        <>
            <SidebarPortal selected={props.selected}>
                <DataUtfprNumbers {...props}></DataUtfprNumbers>
            </SidebarPortal>
            <ViewUtfprNumbers {...props}></ViewUtfprNumbers>
        </>
    )

}

export default EditUtfprNumbers;