// @ts-check

/**
 * @param {Response} page
 * @returns {Promise<string>}
 *
 * pega o conteúdo textual do componente subject/assunto , exemplo :
 *
 * ```
 * let pagePath="/page";
 *
 * getPage(pagePath).then((page)=>{
 *  if(page) {
 *      getSubjectFromPage(page).then(subject){
 *          console.log(subject);
 *      }
 *  }
 * }).catch((e)=>{
 *  console.error(e);
 * });
 *
 *
 * ```
 */
export async function getSubjectFromPage(page) {
  let hat = '';

  //console.log('getSubjectFromPage page', page)

  if (page.ok) {
    try {
      const contents = await page.json();

      //console.log('contents', contents)

      const blocksIDs = Object.keys(contents.blocks);

      for (let i = 0; i < blocksIDs.length; i++) {
        if (contents.blocks[blocksIDs[i]]['@type'] == 'subject') {
          hat = contents.blocks[blocksIDs[i]].subject;
          break;
        }
      }
    } catch (e) {
      console.warn(`page does not have a hat`);
    }
  }

  return hat;
}
