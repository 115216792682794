import { useState, createContext } from 'react';

export const useNavContext = createContext({
  ensinoInstWidth: 'auto',
  setEnsinoInstWidth: () => {},
  pesquisaExtensaoWidth: 'auto',
  setpesquisaExtensaoWidth: () => {},
  pesquisaInstWidth: 'auto',
  setPesquisaInstWidth: () => {},
});

export const NavContextProvider = ({ children }) => {
  const [ensinoInstWidth, setEnsinoInstWidth] = useState('auto');
  const [pesquisaExtensaoWidth, setpesquisaExtensaoWidth] = useState('auto');
  const [pesquisaInstWidth, setPesquisaInstWidth] = useState('auto');

  return (
    <useNavContext.Provider
      value={{
        ensinoInstWidth,
        setEnsinoInstWidth,
        pesquisaExtensaoWidth,
        setpesquisaExtensaoWidth,
        pesquisaInstWidth,
        setPesquisaInstWidth,
      }}
    >
      {children}
    </useNavContext.Provider>
  );
};
