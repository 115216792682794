import React from 'react';
import CampusLayout from './Campi';
import { CustomContainer } from '../general';

const ViewComponent = (props) => {

  const { data } = props;

  return (
    <CustomContainer
      size="ensino-institucional"
      alignCenter={true}
    >
      <CampusLayout
        {...data}
        editable={props.editable}
        onChangeBlock={props.onChangeBlock}
        block={props.block}
      ></CampusLayout>
    </CustomContainer>
  );

}


export default ViewComponent;
