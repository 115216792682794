import view from './View';
import edit from './Edit';
import icon from '../../assets/icons/campi.svg';

const postIt = {
  id: 'campi',
  title: 'Campi',
  edit: edit,
  view: view,
  icon: icon,
  group: 'media',
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};

export default postIt;
