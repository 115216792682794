/**
 * View html block.
 * @module components/manage/Blocks/HTML/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { CustomContainer } from 'addons/utfpr_components/src/components/general/CustomContainers/CustomContainer';

/**
 * View html block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <CustomContainer
      size="ensino-institucional"
      alignCenter={true}
    >
      <div className="block html" dangerouslySetInnerHTML={{ __html: data.html }} />
    </CustomContainer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
