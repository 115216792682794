//@ts-check
import React from 'react';
//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { IconsName } from '../../../assets/icons/buttons';
import { twJoin, twMerge } from 'tailwind-merge';
import { CustomContainer } from '../../general';
import { NavLink } from 'react-router-dom';
import { fixLink } from './fixLink';

const CheckInternal = ({ className, newTab, href, children }) => {
  if (isInternalURL(href) && !href.includes("#")) {
    return (
      <NavLink
        className={className}
        to={flattenToAppURL(href)}
        target={twJoin(newTab ? '_blank' : '_self')}
        rel="noreferrer"
      >
        {children}
      </NavLink>
    );
  } else {
    return (
      <a
        target={twJoin(newTab ? '_blank' : '_self')}
        className={className}
        href={fixLink(href)}
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }
};

const Button = ({ title, icon, size, href, newTab, isEditMode }) => {
  const IconComponente = IconsName[icon];

  return (
    <div className="flex flex-col gap-y-4 items-center">
      <CheckInternal
        newTab={newTab}
        href={href}
        className={twMerge(
          href === '#' || isEditMode
            ? 'cursor-default pointer-events-none'
            : 'hover:bg-dsYellow-500 dark:hover:bg-[#FCBC00] transition cursor-pointer',
          'flex justify-center items-center p-3 rounded-lg bg-dsBlue-600 dark:bg-[#00468C]',
          "max-md:!w-[58px] max-md:!h-[60px] flex !justify-center !items-center"
        )}
      >
        <IconComponente
          color={'#FFFFFF'}
          className={twJoin(
            'min-w-max w-[32px] h-[32px] shrink-0',
            size === '48px' && 'md:w-[48px] md:h-[48px]',
          )}
        />
      </CheckInternal>
      {title && (
        <p className="break-normal sm:break-normal body_medium-tablet_regular text-center text-dsGray-800 dark:text-[#F8FAFA]">
          {title}
        </p>
      )}
    </div>
  );
};

export const GroupButtonsView = (props) => {
  const { data, isEditMode } = props;

  return (
    <CustomContainer
      size="pesquisa-extensao"
      alignCenter={true}
      className="px-4 sm:px-0 grid grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-x-7 gap-y-8"
    >
      {data.buttons?.map((btn, index) => {
        return (
          <Button
            key={index}
            title={btn.title}
            size={data.size ?? '48px'}
            icon={btn.icon ?? 'Default'}
            href={btn.link ?? '#'}
            newTab={btn.newTab ?? false}
            isEditMode={isEditMode}
          />
        );
      })}
    </CustomContainer>
  );
};
