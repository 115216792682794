import ViewUtfprNumbers from "./View.jsx";
import EditUtfprNumbers from "./Edit.jsx";
import UtfprNumbersIcon from '../../assets/icons/numbers.svg';

const config =  {
    id: "UtfprNumbers",
    title: "UTFPR Numbers",
    group: "media",
    view: ViewUtfprNumbers,
    edit: EditUtfprNumbers,
    icon: UtfprNumbersIcon,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1
}

export default config;