import React from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { BlockDataForm } from '@plone/volto/components';
import { geralSchema } from './schema';

const PostItData = (props) => {
  const { onChangeBlock } = props;

  const schema = geralSchema({ ...props });

  return (
    <Segment.Group raised>
      <div>
        <Form>
          <BlockDataForm
            schema={schema}
            title={schema.title}
            onChangeField={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
            formData={props.data}
            block={props.block}
            onChangeBlock={onChangeBlock}
          />
        </Form>
      </div>
    </Segment.Group>
  );
};
export default PostItData;
