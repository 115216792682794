export const geralSchema = (props) => ({

  title: 'Login Card',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['src', 'title', 'description', 'button', 'href'],
    },
  ],

  properties: {
    src: {
      title: "Adicione um arquivo de imagem",
      widget: "file",
    },
    title: {
      widget: 'text',
      title: 'Título:',
    },
    description: {
      widget: 'textarea',
      title: 'Descrição:',
    },
    button: {
      widget: 'text',
      title: 'Botão',
    },
    href: {
      title: 'Link do botão:',
      widget: 'url',
    },
  },
  required: ['file', 'title', 'button', 'href'],

});