import View from './View';
import Edit from './Edit';
import bannerIcon from '../../assets/icons/banner.svg';

export default {
  id: 'banner',
  title: 'Banner',
  icon: bannerIcon,
  group: 'common',
  view: View,
  edit: Edit,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
