import icon from '@plone/volto/icons/list-bullet.svg';
import { ContactEdit } from './ContactEdit';
import { ContactView } from './ContactView';

const contactConfig = {
  id: 'contactCard',
  title: 'ContactCard',
  edit: ContactEdit,
  view: ContactView,
  icon: icon,
  group: 'text',
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};

export default contactConfig;
