/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import PostItTemplate from './components/manage/Blocks/Listing/PostItTemplate';
import ScheduleTemplate from './components/manage/Blocks/Listing/ScheduleTemplate';
import ImagesNewsTemplate from './components/manage/Blocks/Listing/ImagesNewsTemplate';
import HighlightedTemplate from './components/manage/Blocks/Listing/HighlightedTemplate';
import PostItSeartch from './components/manage/Blocks/Listing/PostItSeartch';
import ListingDocuments from './components/manage/Blocks/Listing/ListingDocuments';
import EditalView from './components/theme/View/EditalView';

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['pt-br'],
    defaultLanguage: 'pt-br',
  };

  // JOE: Permitindo apenas certos componentes dentro do Accordion
  // config.blocks.blocksConfig.accordion.allowedBlocks = ['description', 'textComponent', 'titleComponent', 'text', 'slate', 'hiperLink']

  config.blocks.blocksConfig['table'].mostUsed = true;
  config.blocks.blocksConfig['table'].restricted = false;
  config.blocks.blocksConfig['form'].mostUsed = false;
  config.blocks.blocksConfig['tabs_block'].group = 'utfprButtons';
  config.blocks.blocksConfig['__grid'].mostUsed = false;

  config.blocks.blocksConfig.listing.variations.push({
    id: 'postIt',
    title: 'Post It',
    template: PostItTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: 'post',
    title: 'Post It - Pesquisa',
    template: PostItSeartch,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: 'documents',
    title: 'Documents',
    template: ListingDocuments,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: 'schedule',
    title: 'Schedule',
    template: ScheduleTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: 'images',
    title: 'Images News',
    template: ImagesNewsTemplate,
  });

  config.blocks.blocksConfig.listing.variations.push({
    id: 'highlighted',
    title: 'Highlighted',
    template: HighlightedTemplate,
  });

  config.views.contentTypesViews['utf.edital'] = EditalView;

  return config;
}
