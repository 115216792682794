//@ts-check
import React, { useContext, useState } from 'react';
import { EditTableContext } from '../../../store';
//@ts-ignore
import AddLinkForm from '@plone/volto/components/manage/AnchorPlugin/components/LinkButton/AddLinkForm';
import { CellOptionsMenu } from '../components/CellOptionsMenu/CellOptionsMenu';
import { setCell, urlImageHandler, uploadImage } from '../helpers';
import { IconImage } from '../../../../../assets/icons/buttons/IconImage';
//@ts-ignore
import { IconLoader } from '../../../../../../../../addons/utfpr_components/src/assets/icons/buttons/IconLoader';
import { IconCircleWarning } from '../../../../../../../../addons/utfpr_components/src/assets/icons/buttons/IconCircleWarning';
import { urlHandler } from '../../../../../../../../addons/utfpr_components/src/helpers/urlHandler';

// repetindo os procedimentos da ImgCellEdit
/**
 * @param {import('../../types').CellProps<"img">} props
 */
export const ImgCellEdit = (props) => {
  // nos edits use o "EditableTableContext" para ter acesso a tabela
  const { table, setTable } = useContext(EditTableContext);

  const [url, setUrlProto] = useState(props.attributes.url),
    [isCellSelected, setIsCellSelected] = useState(false),
    [text, setTextProto] = useState(props.text),
    [uploadImageState, setUploadImageState] = useState(
      props.attributes.url ? 'done' : 'none',
    ),
    [link, setLinkProto] = useState(props.attributes.link),
    [openNewWindow, setOpenNewWindowProto] = useState(
      props.attributes.openNewWindow,
    );

  const [drag, setDrag] = useState(false);

  /**
   *
   * @param {string} url
   */
  const setUrl = (url) => {
    setUrlProto(url);

    const cell = { ...props };

    cell.attributes.url = url;

    setCell({ setTable: setTable, table: table, cell: cell });
  };

  /**
   *
   * @param {string} text
   */
  const setText = (text) => {
    setTextProto(text);

    const cell = { ...props };

    cell.text = text;

    setCell({ setTable: setTable, table: table, cell: cell });
  };

  /**
   *
   * @param {string} link
   */
  const setLink = (link) => {
    setLinkProto(link);

    const cell = { ...props };

    cell.attributes.link = link;

    setCell({ setTable: setTable, table: table, cell: cell });
  };

  /**
   *
   * @param {boolean} openNewWindow
   */
  const setOpenNewWindow = (openNewWindow) => {
    setOpenNewWindowProto(openNewWindow);

    const cell = { ...props };

    cell.attributes.openNewWindow = openNewWindow;

    setCell({ setTable: setTable, table: table, cell: cell });
  };

  return (
    <>
      {uploadImageState == 'done' ? (
        <>
          <img
            onClick={() => {
              setIsCellSelected(true);
            }}
            className="w-full object-cover h-full"
            src={urlImageHandler(url)}
            title={props.text}
            alt={props.text}
          ></img>
          <IconLoader className={''}></IconLoader>
        </>
      ) : uploadImageState == 'uploading' ? (
        <div
          className={`w-full h-full flex flex-col justify-center bg-zinc-300 dark:bg-slate-700 [&_svg]:fill-lime-500`}
        >
          <IconLoader
            className={'mx-auto animate-[spin_3s_linear_infinite]'}
          ></IconLoader>
        </div>
      ) : uploadImageState == 'error' ? (
        <div
          className={`w-full h-full flex flex-col justify-center bg-zinc-300 dark:bg-slate-700`}
        >
          <IconCircleWarning
            className={'mx-auto [&_path]:stroke-red-500'}
          ></IconCircleWarning>
          <p className="text-red-500 break-all">
            Erro no upload, verifique a conexão
          </p>
        </div>
      ) : (
        <div
          className={`w-full h-full flex flex-col justify-center ${
            drag ? 'bg-zinc-300 dark:bg-slate-700' : ''
          }`}
          onDrop={async (ev) => {
            setDrag(false);
            const files = ev.dataTransfer.files;

            if (!files || !files.length) return;

            const pageURL = new URL(document.URL);

            setUploadImageState('uploading');

            const imgURl = await uploadImage(files, pageURL);

            if (imgURl) setUploadImageState('done');
            else setUploadImageState('error');

            setUrl(imgURl);
          }}
          onDragEnter={() => {
            setDrag(true);
          }}
          onDragExit={() => {
            setDrag(false);
          }}
        >
          <IconImage
            className={`w-20 h-20 object-cover  mx-auto ${
              drag ? '[&_path]:stroke-blue-600' : '[&_path]:stroke-slate-600'
            }`}
          ></IconImage>
          <span className="text-center">Arraste aqui</span>
        </div>
      )}

      <CellOptionsMenu cell={props} isCellSelected={isCellSelected}>
        <div className="flex flex-col gap-2">
          <input
            type="text"
            onChange={(ev) => {
              const inputText = ev.target.value;

              if (inputText.length > 501) {
                setText(inputText);
              } else {
                setText(inputText.substring(0, 500));
              }
            }}
            placeholder="descrição"
            value={text}
          ></input>
          <span>imagem</span>
          <AddLinkForm
            block="draft-js"
            placeholder={'Add imagek'}
            theme={{}}
            data={{ url: url }}
            onChangeValue={(url) => {
              setUploadImageState('done');
              setUrl(url);
            }}
            onClear={() => {
              setUploadImageState('none');
              setUrl('');
            }}
            onOverrideContent={(c) => {}}
          ></AddLinkForm>
          <span>link: {urlHandler(link ? link : '')}</span>
          <AddLinkForm
            block="draft-js"
            placeholder={'Add'}
            theme={{}}
            data={{ url: link }}
            onChangeValue={(link) => {
              setLink(link);
            }}
            onClear={() => {
              setLink('');
            }}
            onOverrideContent={(c) => {}}
          ></AddLinkForm>
          {!link ? (
            <></>
          ) : (
            <label className="gap-4 flex flex-row">
              <input
                type="checkbox"
                checked={openNewWindow}
                onChange={(ev) => {
                  setOpenNewWindow(ev.target.checked);
                }}
              ></input>
              abrir em uma nova aba
            </label>
          )}
        </div>
      </CellOptionsMenu>
    </>
  );
};
