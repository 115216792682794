// @ts-check
import React, { useState, useEffect } from 'react';
import { FiClock } from 'react-icons/fi';
import { ImagePlaceholder } from '../../../../assets/icons/buttons/ImagePlaceholder';
import Chip from '../../../general/Chip/Chip';
import Ping from '../../../general/Ping/Ping';

import { getPage } from 'addons/utfpr_components/src/helpers/getPage';
import { getSubjectFromPage } from 'addons/utfpr_components/src/helpers/getSubjectFromPage';
import { CustomLink } from '../../../general/CustomLink/CustomLink';

const MainNewsItemRow = ({
  img,
  tags,
  title,
  link,
  youtube,
  youtubeState,
  description,
  date,
  editable,
  showImage,
}) => {
  const [imageIsVisible, setImageIsVisible] = useState(true);

  const [hat, setHat] = useState('');

  useEffect(() => {
    async function getHat() {
      const page = await getPage(link);

      //console.log('page', page)

      if (!page) return;

      const subject = await getSubjectFromPage(page);

      setHat(subject);
    }
    getHat();
  });

  return (
    <CustomLink
      className="group w-full h-full flex flex-col justify-start  items-start cursor-pointer bg-white dark:bg-[#001C3A] shadow rounded overflow-hidden"
      href={link}
      isEditMode={editable}
    >
      {showImage ? (
        <div
          className={`w-[400px] ${
            youtube ? '!h-[180px] !min-h-[180px]' : '!h-[320px] !min-h-[320px]'
          }`}
        >
          {imageIsVisible ? (
            <img
              src={img}
              alt={title}
              loading="lazy"
              className="w-[400px] h-[320px] object-cover rounded"
              onError={() => setImageIsVisible(false)}
            />
          ) : (
            <div className="group w-full h-full flex justify-center items-center">
              <ImagePlaceholder
                className="dark:[&_path]:stroke-[#c1c1c1] group-hover:[&_path]:stroke-[#005DB7]"
                size="35"
                color="#000000"
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-col p-6 w-full h-full">
        <h5 className="font-bold text-slate-600 text-[16px] uppercase dark:text-slate-600">
          {hat}
        </h5>
        <h4 className="group-hover:text-[#005DB7] mb-3 text-[22px] font-bold line-clamp-3 leading-7 text-dsGray-900 dark:text-[#F8FAFA]">
          {title}
        </h4>
        <div className="flex justify-start items-center pb-6 gap-2 text-[#8D9199]">
          <FiClock className="text-[14px]" />
          <span className="text-[14px]">{date}</span>
        </div>
        <p className="text-[16px] text-dsGray-900 pb-4 leading-6 dark:text-[#F8FAFA]">
          {description}
        </p>
        <div className="flex justify-start items-start gap-2 mt-auto flex-wrap">
          {tags ? (
            tags.map((tag, index) => {
              //@ts-ignore
              return <Chip key={index} text={tag} />;
            })
          ) : (
            <></>
          )}
          {youtube && youtubeState && youtubeState !== 'none' ? (
            //@ts-ignore
            <Chip>
              <Ping />
              {youtubeState === 'upcoming' && 'Em breve'}
              {youtubeState === 'live' && 'Ao vivo'}
            </Chip>
          ) : (
            <></>
          )}
        </div>
      </div>
    </CustomLink>
  );
};

export default MainNewsItemRow;
