//@ts-check
//@ts-ignore
import config from '@plone/volto/registry';
import {
  flattenToAppURL,
  isInternalURL,
  getAuthToken,
  getBaseUrl,
  //@ts-ignore
} from '@plone/volto/helpers';
//@ts-ignore

/**
 *
 * @param {string} url
 * @returns {Promise<Response|undefined>}
 *
 * this function gets all content from a page as a json
 *
 * esssa função pega todo conteúdo de uma página como json
 */
export async function getPage(url) {
  const flat = flattenToAppURL(url);
  const backendURL = config['_data'].settings.apiPath + '/++api++' + flat;
  //console.log('backendURL', backendURL)
  const token = getAuthToken();
  try {
    return fetch(backendURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch {
    return;
  }
}
