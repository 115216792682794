import {BlockDataForm} from '@plone/volto/components';
import { schemaUtfprNumbers } from './Schema';

const DataUtfprNumbers = (props)=>{
    const {block, onChangeBlock, data, intl} = props;
    let schema = schemaUtfprNumbers({data, intl});

    return(<BlockDataForm
        schema={schema}
        onChangeField={(id, value)=>{
            onChangeBlock(block, {...data, [id]: value})
        }}
        formData={data}>

    </BlockDataForm>)
}

export default DataUtfprNumbers;