import OurPeopleView from './OurPeopleView';
import OurPeopleEdit from './OurPeopleEdit';
import ourPeopleIcon from '../../assets/icons/ourPeople.svg';

export default {
  id: 'ourPeople',
  title: 'Our People',
  group: 'media',
  view: OurPeopleView,
  edit: OurPeopleEdit,
  icon: ourPeopleIcon,
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
};
