import TitleComponentView from './TitleComponentView';
import TitleComponentEdit from './TitleComponentEdit';
import titleComponentIcon from '../../assets/icons/titleComponent.svg';

export default {
  id: 'titleComponent',
  title: 'Title Component',
  group: 'common',
  view: TitleComponentView,
  edit: TitleComponentEdit,
  icon: titleComponentIcon,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
};
