import ButtonsView from './ButtonsView';
import ButtonsEdit from './ButtonsEdit';
import icon from '@plone/volto/icons/list-bullet.svg';

export default {
  id: 'gridButtons',
  title: 'Grid Buttons',
  group: 'common',
  view: ButtonsView,
  edit: ButtonsEdit,
  icon: icon,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
};
