import React, { useState } from 'react';
import Chip from '../../../general/Chip/Chip';
import { FiClock } from 'react-icons/fi';
import { ImagePlaceholder } from '../../../../assets/icons/buttons/ImagePlaceholder';
import { CustomLink } from '../../../general/CustomLink/CustomLink';

const MainNewsItemColumn = ({
  img,
  tags,
  title,
  link,
  description,
  date,
  editable,
  showImage,
}) => {
  const [imageIsVisible, setImageIsVisible] = useState(showImage);

  return (
    <CustomLink
      className="w-full group hover:text-[#005DB7] bg-white dark:bg-[#001C3A] shadow rounded overflow-hidden"
      href={link}
      isEditMode={editable}
    >
      <div className="flex justify-start">
        <div className="w-full flex flex-col gap-3 p-6 pr-12">
          <div className="text-[22px] group-hover:text-[#005DB7]  leading-7 font-bold line-clamp-3 text-dsGray-900 dark:text-[#F8FAFA]">
            {title}
          </div>
          <p className="text-[#8D9199] text-[14px] flex flex-row gap-2 pb-3 items-center ">
            <FiClock className="text-[14px]" />
            Publicado {date}, última modificação {date}
          </p>
          <div className="text-[16px] text-[#0D1117] leading-6 text-dsGray-900 dark:text-[#F8FAFA]">
            {description}
          </div>
          {tags.length > 0 && (
            <div className="flex justify-start items-start gap-2">
              {tags.map((tag, index) => {
                return <Chip key={index} text={tag} />;
              })}
            </div>
          )}
        </div>
        <div className="w-[240px] h-[240px] min-w-[240px]">
          {imageIsVisible ? (
            <img
              src={img}
              alt={title}
              className="h-full object-cover"
              loading="lazy"
              onError={() => setImageIsVisible(false)}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <ImagePlaceholder
                color="#000000"
                className="dark:[&_path]:stroke-[#FFFFFF] group-hover:[&_path]:stroke-[#005DB7]"
              />
            </div>
          )}
        </div>
      </div>
    </CustomLink>
  );
};

export default MainNewsItemColumn;
