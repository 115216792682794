//@ts-check
import './styles.css';
import React, { useEffect, useState } from 'react';
import { IconMoon } from '../../../../../../addons/utfpr_components/src/assets/icons/IconMoon';
import { IconSun } from '../../../../../../addons/utfpr_components/src/assets/icons/IconSun';
import { IconAccessibility } from '../../../../../../addons/utfpr_components/src/assets/icons/IconAccessibility';
//@ts-ignore
import { Anontools } from '@plone/volto/components';
import { useContext } from 'react';
import { useDarkModeContext } from '../../../../../../store/DarkModeStore';
import { twJoin } from 'tailwind-merge';
import { useHistory } from 'react-router-dom';

const CustomizeMenuDesktop = ({ token }) => {
  //@ts-ignore
  const { darkMode, setDarkMode } = useContext(useDarkModeContext);
  const [user, setUser] = useState('Eu sou');
  const history = useHistory();

  const toggleDarkMode = () => {
    if (darkMode === 'on') {
      setDarkMode('off');
      return;
    }

    setDarkMode('on');
  };

  const optionUrls = {
    estudante: '/alunos',
    servidor: '/servidores/site',
    terceirizado: '/',
    egresso: '/alunos/egressos',
    futuroAluno: '/cursos/estudenautfpr',
  };

  function handleUserChange(event) {
    const url = optionUrls[event.target.value];
    if (url) {
      setUser(event.target.value);
      history.push(url);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', accessibilityHandleKeydown);

    window.addEventListener('keyup', accessibilityHandleKeyup);

    return () => {
      window.removeEventListener('keydown', accessibilityHandleKeydown);
      window.removeEventListener('keyup', accessibilityHandleKeyup);
    };
  }, []);

  //CIDADES
  const optionCamp = {
    apucarana: '/campus/apucarana',
    campoMourao: '/campus/campomourao',
    cornelioProcopio: '/campus/cornelioprocopio',
    curitiba: '/campus/curitiba',
    doisVizinhos: '/campus/doisvizinhos',
    franciscoBeltrao: '/campus/franciscobeltrao',
    guarapuava: '/campus/guarapuava',
    londrina: '/campus/londrina',
    medianeira: '/campus/medianeira',
    patoBranco: '/campus/patobranco',
    pontaGrossa: '/campus/pontagrossa',
    reitoria: '/estrutura/reitoria',
    santaHelena: '/campus/santahelena',
    toledo: '/campus/toledo',
  };

  function handleCampChange(event) {
    const url = optionCamp[event.target.value];
    if (url) {
      history.push(url);
    }
  }

  return (
    <div
      className={`bg-[#FFBE00] hidden px-9 h-16 sm:grid grid-cols-[2fr_1fr] lg:grid-cols-[1fr_3fr_1fr] justify-items-center items-center gap-x-6`}
    >
      <div className="col-start-1 col-end-2 lg:col-start-2 lg:col-end-3 flex w-full justify-center items-center">
        <p className="font-dsButton text-left">Meu portal personalizado</p>
        <select
          onChange={handleUserChange}
          defaultValue={'Eu sou'}
          className={`font-dsButton px-3 py-2 rounded-md w-full max-w-[213px] mr-3 ml-6`}
        >
          <option value="Eu sou" className="font-extrabold" disabled>
            Eu sou
          </option>
          <option value="estudante">Estudante</option>
          <option value="servidor">Servidor</option>
          <option value="terceirizado">Terceirizado</option>
          <option value="egresso">Egresso</option>
          <option value="futuroAluno">Futuro aluno</option>
        </select>
        <select
          onChange={handleCampChange}
          defaultValue={'Cidade'}
          className={`font-dsButton px-3 py-2 w-full max-w-[213px] rounded-md`}
        >
          <option value="Cidade" className="font-extrabold" disabled>
            Cidade
          </option>
          <option value="apucarana">Apucarana</option>
          <option value="campoMourao">Campo Mourão</option>
          <option value="cornelioProcopio">Cornélio Procópio</option>
          <option value="curitiba">Curitiba</option>
          <option value="doisVizinhos">Dois Vizinhos</option>
          <option value="franciscoBeltrao">Francisco Beltrão</option>
          <option value="guarapuava">Guarapuava</option>
          <option value="londrina">Londrina</option>
          <option value="medianeira">Medianeira</option>
          <option value="patoBranco">Pato Branco</option>
          <option value="pontaGrossa">Ponta Grossa</option>
          <option value="reitoria">Reitoria</option>
          <option value="santaHelena">Santa Helena</option>
          <option value="toledo">Toledo</option>
        </select>
      </div>
      <div className="col-start-2 col-end-3 lg:col-start-3 lg:col-end-4 flex w-full h-full justify-end items-center gap-x-8">
        <button
          className={twJoin(
            darkMode === 'on' ? 'inline-block' : 'hidden',
            'group h-fit w-fit flex justify-center items-center',
          )}
          onClick={toggleDarkMode}
        >
          <IconSun size={'32'} changeColorOnHover={true} />
        </button>
        <button
          className={twJoin(
            darkMode === 'on' ? 'hidden' : 'inline-block',
            'group h-fit w-fit flex justify-center items-center',
          )}
          onClick={toggleDarkMode}
        >
          <IconMoon size={'32'} changeColorOnHover={true} className={''} />
        </button>
        <button
          className={twJoin(
            'group h-fit w-fit flex justify-center items-center',
          )}
          onClick={() => history.push('/acessibilidade')}
        >
          <IconAccessibility
            size={'32'}
            changeColorOnHover={true}
            className={''}
          />
        </button>
        {!token && <Anontools user={user} />}
      </div>
    </div>
  );
};

class KeysPressed {
  /**@type {string[]} */
  _keys = [];

  /**
   * @param {string} key
   * @returns {void}
   */
  addKey(key) {
    const includesKey = this._keys.includes(key);

    if (includesKey) return;

    this._keys.push(key);
  }

  /**
   * @param {string} key
   * @returns {void}
   */
  removeKey(key) {
    const indexOfKey = this._keys.indexOf(key);

    if (indexOfKey == -1) return;

    this._keys.splice(indexOfKey, 1);
  }

  /**
   * @returns {string[]}
   */
  getKeys() {
    return [...this._keys];
  }

  /**
   *
   * @param {string[]} keys
   * @returns {boolean}
   */
  contains(keys) {
    for (let i = 0; i < keys.length; i++)
      if (!this._keys.includes(keys[i])) return false;

    return true;
  }

  /**
   *
   * @param {string[][]} keys
   * @returns {boolean}
   */
  containsCombination(keys) {
    for (let i = 0; i < keys.length; i++)
      if (this.contains(keys[i])) return true;

    return false;
  }
}

const keys = new KeysPressed();

/**
 * @param {KeyboardEvent} k
 */
function accessibilityHandleKeydown(k) {
  const commands = {
    goTitle: {
      keys: [
        ['Shift', 'Alt', '!'], // firefox
        ['Alt', '1'], // chrome
        ['Alt', 'Control', '1'], // Mac
      ],
      el: window.document.getElementById('main-title'),
    },
    goStartMenu: {
      keys: [
        ['Shift', 'Alt', '@'], // firefox
        ['Alt', '2'], // chrome
        ['Alt', 'Control', '2'], // Mac
      ],
      el: window.document.getElementById('navContainer'),
    },
    goInternalSearch: {
      keys: [
        ['Alt', 'Shift', '#'], // firefox
        ['Alt', '3'], // chrome
        ['Alt', 'Control', '3'], // Mac
      ],
      el: window.document.getElementById('search-input'),
    },
    goFooter: {
      keys: [
        ['Shift', 'Alt', '$'], // firefox
        ['Alt', '4'], // chrome
        ['Alt', 'Control', '4'], // Mac
      ],
      el: window.document.getElementById('main-footer'),
    },
  };

  const brasilBar = window.document.getElementById('barra-brasil');

  keys.addKey(k.key);

  if (keys.containsCombination(commands.goTitle.keys))
    if (commands.goTitle.el) commands.goTitle.el.scrollIntoView();
    else brasilBar?.scrollIntoView();
  else if (keys.containsCombination(commands.goStartMenu.keys))
    commands.goStartMenu.el?.scrollIntoView();
  else if (keys.containsCombination(commands.goFooter.keys))
    commands.goFooter.el?.scrollIntoView();
  else if (keys.containsCombination(commands.goInternalSearch.keys)) {
    commands.goInternalSearch.el?.scrollIntoView();
    //@ts-ignore
    commands.goInternalSearch.el?.select();
  }
}

function accessibilityHandleKeyup(k) {
  keys.removeKey(k.key);
}

export default CustomizeMenuDesktop;
