//@ts-check
import { IconArrowRight } from '../../../assets/icons/buttons/IconArrowRight';
import { CustomLink } from '../../general/CustomLink/CustomLink';
import React, { useEffect } from 'react';

/**
 * @type {React.FC<{img:string,name:string,role:string,linkText:string,linkURL:string,isEditMode:boolean,testimony:string}>}
 */
export const OurPeopleTestimonyCard = ({
  img,
  name,
  role,
  testimony,
  isEditMode,
  linkText,
  linkURL,
}) => {
  /** @param {React.MouseEvent<HTMLAnchorElement, MouseEvent>} ev*/
  const preventDefaultEditable = (ev) => {
    if (isEditMode) ev.preventDefault();
  };

  return (
    <div className="flex flex-col gap-5 justify-between h-full w-full p-4 bg-[#F2F4F4] dark:bg-[#001C3A] hover:bg-[#e7ebeb] dark:hover:bg-[#003063] transition duration-300 rounded-sm">
      <div className="flex gap-5 flex-col">
        <div className="grid grid-cols-4 gap-4">
          <img
            className="aspect-square w-11/12 object-cover rounded-full my-auto"
            src={img}
            loading="lazy"
          ></img>
          <div className="flex flex-col col-span-3 my-auto">
            <h1 className="dark:text-[#F8FAFA] font-dsSubtitle font-bold text-dsGray-900 break-all">
              {name}
            </h1>
            <p className="dark:text-[#F8FAFA] font-dsCaption tracking-[.07em] leading-6 text-left uppercase text-dsGray-900">
              {role}
            </p>
          </div>
        </div>
        <p className="text-left">{testimony}</p>
      </div>
      {linkURL ? (
        <CustomLink
          isEditMode={isEditMode}
          href={linkURL}
          className="flex flex-row gap-2 text-[#0169CD] transition-all group-hover:text-[#FCBC00] dark:group-hover:text-[#FCBC00] dark:hover:text-[#FCBC00] dark:text-[#A9C7FF] title_small-semibold group"
        >
          <span className="text-sm leading-none my-auto transition-all">
            {linkText}
          </span>
          <IconArrowRight
            size="22"
            className="dark:[&_path]:stroke-[#A9C7FF] group-hover:[&_path]:stroke-[#FCBC00] dark:group-hover:[&_path]:stroke-[#FCBC00] transition-all"
          ></IconArrowRight>
        </CustomLink>
      ) : (
        <></>
      )}
    </div>
  );
};
